import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ClientTagFragmentDoc } from '../../fragments/ClientTag.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSalonTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchSalonTagsQuery = { __typename?: 'RootQueryType', fetchSalonTags: Array<{ __typename?: 'ClientTag', color: string, name: string, id: any }> };


export const FetchSalonTagsDocument = gql`
    query FetchSalonTags {
  fetchSalonTags {
    ...ClientTag
  }
}
    ${ClientTagFragmentDoc}`;

/**
 * __useFetchSalonTagsQuery__
 *
 * To run a query within a React component, call `useFetchSalonTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSalonTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSalonTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSalonTagsQuery(baseOptions?: Apollo.QueryHookOptions<FetchSalonTagsQuery, FetchSalonTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSalonTagsQuery, FetchSalonTagsQueryVariables>(FetchSalonTagsDocument, options);
      }
export function useFetchSalonTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSalonTagsQuery, FetchSalonTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSalonTagsQuery, FetchSalonTagsQueryVariables>(FetchSalonTagsDocument, options);
        }
export type FetchSalonTagsQueryHookResult = ReturnType<typeof useFetchSalonTagsQuery>;
export type FetchSalonTagsLazyQueryHookResult = ReturnType<typeof useFetchSalonTagsLazyQuery>;
export type FetchSalonTagsQueryResult = Apollo.QueryResult<FetchSalonTagsQuery, FetchSalonTagsQueryVariables>;