import { useTranslation } from "react-i18next";
import PullToRefresh from "react-simple-pull-to-refresh";

import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/Button";
import { Spinner } from "@/components/ui/Spinner";
import { SalonPlanCard } from "@/features/payments/components/SalonPlanCard";
import { useUserAgent } from "@/hooks/use-user-agent";
import { useSessionContext } from "@/providers/SessionProvider";

export const BuySubscriptionView = () => {
  const { t } = useTranslation();
  const { isOwner, logout } = useSessionContext();
  const { isMobileApp } = useUserAgent();

  return (
    <PullToRefresh
      isPullable={isMobileApp}
      canFetchMore={false}
      pullDownThreshold={200}
      maxPullDownDistance={230}
      resistance={2.5}
      backgroundColor="#f5f5f4"
      refreshingContent={
        <div className="flex h-full items-center bg-stone-100 py-8">
          <Spinner className="size-8" />
        </div>
      }
      pullingContent={
        <p className="flex h-auto flex-1 items-center justify-center py-8 text-sm text-gold-500">
          &#8615;&nbsp;&nbsp;{t("actions.refresh")}&nbsp;&nbsp;&#8615;
        </p>
      }
      onRefresh={async () => await window.location.reload()}>
      <div className="relative flex h-screen flex-1 overflow-y-auto overscroll-none bg-white px-4 sm:px-6 sm:py-24 md:grid lg:px-8">
        <div className="mx-auto max-w-max py-16">
          <div className="fixed left-0 top-0 z-10 flex h-12 w-full justify-center bg-white sm:h-16">
            <div className="flex w-full max-w-5xl justify-end px-8 lg:px-0">
              <Button
                size="small"
                variant="primary-inline"
                startIcon={<ArrowRightOnRectangleIcon />}
                onClick={logout}>
                {t("header.mainMenu.logOut")}
              </Button>
            </div>
          </div>
          <div className="h-full">
            <div className="mx-auto max-w-7xl px-6 py-12  sm:py-16 lg:px-8">
              {isOwner ? (
                <>
                  <div className="mx-auto max-w-4xl sm:text-center">
                    <h2 className="text-pretty font-serif text-5xl font-semibold tracking-tight text-stone-900 sm:text-balance sm:text-6xl">
                      {t("subscriptions.owner.header")}
                    </h2>
                    <p className="mx-auto mt-6 max-w-xl text-pretty text-lg text-stone-500 sm:text-xl/8">
                      {t("subscriptions.owner.description")}
                    </p>
                  </div>
                  <div className="mt-8">
                    <SalonPlanCard />
                  </div>
                </>
              ) : (
                <div className="mx-auto max-w-4xl sm:text-center">
                  <h2 className="text-pretty font-serif text-5xl font-semibold tracking-tight text-stone-900 sm:text-balance sm:text-6xl">
                    {t("subscriptions.employee.header")}
                  </h2>
                  <p className="mx-auto mt-6 max-w-xl text-pretty text-lg text-stone-500 sm:text-xl/8">
                    {t("subscriptions.employee.description")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PullToRefresh>
  );
};
