import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { BlockedSlotFragmentDoc } from '../fragments/BlockedSlot.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlockSlotMutationVariables = Types.Exact<{
  data: Types.BlockSlotInput;
}>;


export type BlockSlotMutation = { __typename?: 'RootMutationType', blockSlot: { __typename?: 'BlockedSlot', description?: string | null, employeeName: string, employeeId: any, title?: string | null, id: any, insertedAt: any, timeRange: { __typename?: 'Timerange', from: any, to: any } } };


export const BlockSlotDocument = gql`
    mutation BlockSlot($data: BlockSlotInput!) {
  blockSlot(data: $data) {
    ...BlockedSlot
  }
}
    ${BlockedSlotFragmentDoc}`;
export type BlockSlotMutationFn = Apollo.MutationFunction<BlockSlotMutation, BlockSlotMutationVariables>;

/**
 * __useBlockSlotMutation__
 *
 * To run a mutation, you first call `useBlockSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockSlotMutation, { data, loading, error }] = useBlockSlotMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBlockSlotMutation(baseOptions?: Apollo.MutationHookOptions<BlockSlotMutation, BlockSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockSlotMutation, BlockSlotMutationVariables>(BlockSlotDocument, options);
      }
export type BlockSlotMutationHookResult = ReturnType<typeof useBlockSlotMutation>;
export type BlockSlotMutationResult = Apollo.MutationResult<BlockSlotMutation>;
export type BlockSlotMutationOptions = Apollo.BaseMutationOptions<BlockSlotMutation, BlockSlotMutationVariables>;