import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SmsFragment = { __typename?: 'Sms', clientId: any, firstName?: string | null, lastName?: string | null, message?: string | null, recipient?: string | null, salonId: any, senderName?: string | null, sentAt?: any | null, status: Types.SmsStatus, id: any };

export const SmsFragmentDoc = gql`
    fragment Sms on Sms {
  clientId
  firstName
  lastName
  message
  recipient
  salonId
  senderName
  sentAt
  status
  id
  status
}
    `;