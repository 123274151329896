import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type TreatmentFragment = { __typename?: 'Treatment', categoryId: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, id: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean };

export const TreatmentFragmentDoc = gql`
    fragment Treatment on Treatment {
  categoryId
  duration
  insertedAt
  isDeleted
  isExactPrice
  name
  price
  priceFrom
  priceTo
  updatedAt
  id
  description
  isPublic
  imagesUrls
  hasEmployeesAssigned
}
    `;