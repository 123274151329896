import { ChangeEvent } from "react";
import InputMask from "react-input-mask";

import { TimerangeOption, getNormalizedTimeValue, getTimeFieldMask } from "@utils/datetime";

import { AutoComplete } from "@/components/ui/AutoComplete";
import { TIME_VALUE_LENGTH } from "@/constants";
import { useBreakpoint } from "@/hooks/use-breakpoint";

import { MobileTimePicker } from "./MobileTimePicker";

type TimeAutoCompleteProps = {
  timeOptions: TimerangeOption[];
  value: string;
  label?: string;
  onChange?: (value: string | ChangeEvent<HTMLInputElement>) => void;
};

export const TimeAutoComplete = ({
  timeOptions,
  value = "",
  label,
  onChange,
}: TimeAutoCompleteProps) => {
  const { isMd } = useBreakpoint("md");

  return isMd ? (
    <AutoComplete
      value={value}
      options={timeOptions}
      onChange={(value) => onChange && onChange(value)}
      ignoreFilter={value.length === TIME_VALUE_LENGTH}
      defaultOptionIndex={
        value.length === TIME_VALUE_LENGTH
          ? timeOptions.findIndex((option) => option.value.includes(value.slice(0, 2)))
          : 0
      }
      renderField={(props) => (
        <div className="relative flex h-12 w-full items-center justify-between rounded-md border border-stone-300 bg-white text-xs text-stone-400">
          {label && <span className="absolute left-3 z-0 text-xs text-stone-400">{label}</span>}
          <InputMask
            type="text"
            mask={getTimeFieldMask(value)}
            maskPlaceholder={null}
            beforeMaskedStateChange={({ nextState }) => ({
              ...nextState,
              value: getNormalizedTimeValue(nextState.value),
            })}
            className="flex w-full appearance-none border-none bg-transparent pl-8 text-right text-sm text-stone-500 ring-0 focus:border-none focus:ring-0 focus:ring-offset-0"
            style={{ zIndex: 1 }}
            {...props}
          />
        </div>
      )}
    />
  ) : (
    <MobileTimePicker value={value} label={label} onChange={onChange} />
  );
};
