import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppointmentConnectionFragmentDoc } from '../fragments/AppointmentConnection.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchBulkAppointmentsPaginatedQueryVariables = Types.Exact<{
  filters: Types.AppointmentFilters;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  query?: Types.InputMaybe<Types.Scalars['String']>;
  sortByDatetime?: Types.InputMaybe<Types.SortOrder>;
}>;


export type FetchBulkAppointmentsPaginatedQuery = { __typename?: 'RootQueryType', fetchBulkAppointmentsPaginated: { __typename?: 'AppointmentConnection', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'AppointmentEdge', cursor?: string | null, node: { __typename?: 'Appointment', appointmentId: any, clientId?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: Types.AppointmentStatus, totalPrice: number, paymentMethodId?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentId: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentId: any, employeeId?: any | null, treatmentId: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } }> } };


export const FetchBulkAppointmentsPaginatedDocument = gql`
    query FetchBulkAppointmentsPaginated($filters: AppointmentFilters!, $after: String, $first: Int, $query: String, $sortByDatetime: SortOrder) {
  fetchBulkAppointmentsPaginated(
    filters: $filters
    after: $after
    first: $first
    query: $query
    sortByDatetime: $sortByDatetime
  ) {
    ...AppointmentConnection
  }
}
    ${AppointmentConnectionFragmentDoc}`;

/**
 * __useFetchBulkAppointmentsPaginatedQuery__
 *
 * To run a query within a React component, call `useFetchBulkAppointmentsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBulkAppointmentsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBulkAppointmentsPaginatedQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *      sortByDatetime: // value for 'sortByDatetime'
 *   },
 * });
 */
export function useFetchBulkAppointmentsPaginatedQuery(baseOptions: Apollo.QueryHookOptions<FetchBulkAppointmentsPaginatedQuery, FetchBulkAppointmentsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchBulkAppointmentsPaginatedQuery, FetchBulkAppointmentsPaginatedQueryVariables>(FetchBulkAppointmentsPaginatedDocument, options);
      }
export function useFetchBulkAppointmentsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchBulkAppointmentsPaginatedQuery, FetchBulkAppointmentsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchBulkAppointmentsPaginatedQuery, FetchBulkAppointmentsPaginatedQueryVariables>(FetchBulkAppointmentsPaginatedDocument, options);
        }
export type FetchBulkAppointmentsPaginatedQueryHookResult = ReturnType<typeof useFetchBulkAppointmentsPaginatedQuery>;
export type FetchBulkAppointmentsPaginatedLazyQueryHookResult = ReturnType<typeof useFetchBulkAppointmentsPaginatedLazyQuery>;
export type FetchBulkAppointmentsPaginatedQueryResult = Apollo.QueryResult<FetchBulkAppointmentsPaginatedQuery, FetchBulkAppointmentsPaginatedQueryVariables>;