import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentSubscriptionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentSubscriptionQuery = { __typename?: 'RootQueryType', currentSubscription?: { __typename?: 'Subscription2', currentPeriodStart: any, currentPeriodEnd: any, id: string, status: Types.SubscriptionStatus, latestInvoice?: { __typename?: 'Invoice', id: string, status: Types.InvoiceStatus, url?: string | null, amountDue: { __typename?: 'Money', amount: number, currency: string }, amountPaid: { __typename?: 'Money', amount: number, currency: string }, amountRemaining: { __typename?: 'Money', amount: number, currency: string } } | null } | null };


export const CurrentSubscriptionDocument = gql`
    query CurrentSubscription {
  currentSubscription {
    currentPeriodStart
    currentPeriodEnd
    id
    latestInvoice {
      amountDue {
        amount
        currency
      }
      amountPaid {
        amount
        currency
      }
      amountRemaining {
        amount
        currency
      }
      id
      status
      url
    }
    status
  }
}
    `;

/**
 * __useCurrentSubscriptionQuery__
 *
 * To run a query within a React component, call `useCurrentSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<CurrentSubscriptionQuery, CurrentSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentSubscriptionQuery, CurrentSubscriptionQueryVariables>(CurrentSubscriptionDocument, options);
      }
export function useCurrentSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentSubscriptionQuery, CurrentSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentSubscriptionQuery, CurrentSubscriptionQueryVariables>(CurrentSubscriptionDocument, options);
        }
export type CurrentSubscriptionQueryHookResult = ReturnType<typeof useCurrentSubscriptionQuery>;
export type CurrentSubscriptionLazyQueryHookResult = ReturnType<typeof useCurrentSubscriptionLazyQuery>;
export type CurrentSubscriptionQueryResult = Apollo.QueryResult<CurrentSubscriptionQuery, CurrentSubscriptionQueryVariables>;