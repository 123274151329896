import { ApolloQueryResult } from "@apollo/client";
import { createContext, ReactNode, useContext, useMemo } from "react";

import { useSessionStore } from "@/features/session/hooks";
import { CurrentSession, CurrentSessionQuery, Exact, Role } from "@/types";

interface SessionContextProps {
  loading: boolean;
  session: CurrentSession | undefined;
  isOnboarded: boolean;
  isEmailVerified: boolean;
  permissions: any;
  isOwner: boolean;
  isEmployee: boolean;
  hasActiveSubscription: boolean;
  trialUsed?: boolean | null;
  role?: Role | null;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<CurrentSessionQuery>>;
  logout: () => void;
}

const SessionContext = createContext<SessionContextProps | undefined>(undefined);

export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const {
    loading,
    session,
    isOnboarded,
    isEmailVerified,
    permissions,
    isOwner,
    isEmployee,
    trialUsed,
    hasActiveSubscription,
    role,
    refetch,
    logout,
  } = useSessionStore();

  const value = useMemo(
    () => ({
      loading,
      session,
      isOnboarded,
      isEmailVerified,
      hasActiveSubscription,
      permissions,
      isOwner,
      isEmployee,
      trialUsed,
      role,
      refetch,
      logout,
    }),
    [
      loading,
      session,
      isOnboarded,
      isEmailVerified,
      hasActiveSubscription,
      permissions,
      isOwner,
      isEmployee,
      trialUsed,
      role,
      refetch,
      logout,
    ],
  );

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export const useSessionContext = (): SessionContextProps => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};
