import { InputHTMLAttributes, cloneElement } from "react";
import { Control, Controller, UseControllerProps } from "react-hook-form";
import InputMask from "react-input-mask";

import { generateTimeOptions, getNormalizedTimeValue, getTimeFieldMask } from "@utils/datetime";

import { TIME_AUTOCOMPLETE_STEP, TIME_VALUE_LENGTH } from "@/constants";
import { TIME_PATTERN } from "@/constants/validations";
import { Icon } from "@/models";
import { cn } from "@/utils/utils";

import { AutoComplete } from "./AutoComplete";

const timeOptions = generateTimeOptions(TIME_AUTOCOMPLETE_STEP);

type TimeFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  control: Control<any>;
  placeholder?: string;
  className?: string;
  startIcon?: Icon;
  isError?: boolean;
  rules?: UseControllerProps["rules"];
};

export const TimeField = ({
  label,
  name,
  control,
  placeholder,
  className,
  startIcon,
  isError,
  rules,
  ...delegated
}: TimeFieldProps) => {
  return (
    <div className={cn("relative", className)}>
      <label htmlFor={name} className="sr-only">
        {label || name}
      </label>
      {startIcon && (
        <div
          className={cn("pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3", {
            "mb-4": !!isError,
          })}
          aria-hidden="true">
          {cloneElement(startIcon, {
            className: cn("h-5 w-5 text-stone-400", startIcon.props.className, {
              "text-red-600": !!isError,
            }),
          })}
        </div>
      )}
      <Controller
        control={control}
        name={name}
        rules={{
          pattern: TIME_PATTERN,
          ...rules,
        }}
        render={({ field: { onChange, value = "" } }) => (
          <AutoComplete
            value={value}
            onChange={onChange}
            options={timeOptions}
            ignoreFilter={value.length === TIME_VALUE_LENGTH}
            defaultOptionIndex={
              value.length === TIME_VALUE_LENGTH
                ? timeOptions.findIndex((option) => option.value.includes(value.slice(0, 2)))
                : 0
            }
            renderField={(props) => (
              <InputMask
                type="text"
                name={name}
                id={name}
                mask={getTimeFieldMask(value)}
                maskPlaceholder={null}
                beforeMaskedStateChange={({ nextState }) => ({
                  ...nextState,
                  value: getNormalizedTimeValue(nextState.value),
                })}
                className={cn(
                  "flex h-12 w-16 appearance-none items-center rounded-md border px-2 text-center text-sm placeholder-stone-400 focus:outline-none disabled:cursor-not-allowed disabled:border-stone-300 disabled:bg-stone-100 disabled:text-stone-400",
                  className,
                  {
                    "pl-10": !!startIcon,
                  },
                  isError
                    ? "border-red-600 focus:border-red-600 focus:ring-red-600"
                    : "border-stone-300 focus:border-gold-600 focus:ring-gold-600",
                )}
                placeholder={placeholder}
                {...delegated}
                {...props}
              />
            )}
          />
        )}
      />
    </div>
  );
};
