import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TimerangeFragmentDoc } from '../../../fragments/TimeRange.generated';
export type AppointmentTreatmentFragment = { __typename?: 'AppointmentTreatment', appointmentTreatmentId: any, employeeId?: any | null, treatmentId: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } };

export const AppointmentTreatmentFragmentDoc = gql`
    fragment AppointmentTreatment on AppointmentTreatment {
  appointmentTreatmentId
  employeeId
  treatmentId
  employeeName
  treatmentName
  categoryColor
  finalPrice
  suggestedPrice
  timeRange {
    ...Timerange
  }
}
    ${TimerangeFragmentDoc}`;