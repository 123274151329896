import { useTranslation } from "react-i18next";

import { SalonPlanCard } from "@/features/payments/components/SalonPlanCard";

import { BusinessPlanCard } from "./BusinessPlanCard";

export const Plans = () => {
  const { t } = useTranslation();

  return (
    <div className="rounded-2xl bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl sm:text-center">
          <h2 className="text-pretty font-serif text-5xl font-semibold tracking-tight text-stone-900 sm:text-balance sm:text-6xl">
            {t("pricing.header")}
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-pretty text-lg text-stone-500 sm:text-xl/8">
            {t("pricing.description")}
          </p>
        </div>
        <div className="mt-16 sm:mt-20">
          <SalonPlanCard />
        </div>
        <BusinessPlanCard />
      </div>
    </div>
  );
};
