import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SalonFragmentDoc } from '../fragments/Salon.generated';
import { AccountWithProfileFragmentDoc } from '../../account/fragments/AccountWithProfile.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnboardMutationVariables = Types.Exact<{
  salonData: Types.CreateSalonInput;
  accountData: Types.AccountUpdateInput;
}>;


export type OnboardMutation = { __typename?: 'RootMutationType', createSalon: { __typename?: 'Salon', salonId: any, name: string, phoneType?: Types.PhoneType | null, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: any | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, socials?: any | null, description?: string | null, isPublic: boolean, slug?: string | null, selfBooking: { __typename?: 'SalonSelfBooking', enabled: boolean, slotDuration: number, automaticalSlotApproval: boolean, automaticalSlotApprovalSms: boolean, minLeadTime: Types.MinLeadTimeOptionsInMinutes, maxLeadTime: Types.MaxLeadTimeOptionsInDays, url: any } }, updateAccount: { __typename?: 'AccountWithProfile', email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null } };


export const OnboardDocument = gql`
    mutation Onboard($salonData: CreateSalonInput!, $accountData: AccountUpdateInput!) {
  createSalon(data: $salonData) {
    ...Salon
  }
  updateAccount(data: $accountData) {
    ...AccountWithProfile
  }
}
    ${SalonFragmentDoc}
${AccountWithProfileFragmentDoc}`;
export type OnboardMutationFn = Apollo.MutationFunction<OnboardMutation, OnboardMutationVariables>;

/**
 * __useOnboardMutation__
 *
 * To run a mutation, you first call `useOnboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardMutation, { data, loading, error }] = useOnboardMutation({
 *   variables: {
 *      salonData: // value for 'salonData'
 *      accountData: // value for 'accountData'
 *   },
 * });
 */
export function useOnboardMutation(baseOptions?: Apollo.MutationHookOptions<OnboardMutation, OnboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardMutation, OnboardMutationVariables>(OnboardDocument, options);
      }
export type OnboardMutationHookResult = ReturnType<typeof useOnboardMutation>;
export type OnboardMutationResult = Apollo.MutationResult<OnboardMutation>;
export type OnboardMutationOptions = Apollo.BaseMutationOptions<OnboardMutation, OnboardMutationVariables>;