import { Navigate, Outlet } from "react-router-dom";

import { Spinner } from "@components/ui/Spinner";

import { ROUTES } from "@/features/router/constants/routes";
import { useRouterUtils } from "@/features/router/hooks/use-router-utils";
import { useSessionContext } from "@/providers/SessionProvider";

export const AuthenticatedRoutes = () => {
  const { session, loading, isOnboarded, isEmailVerified, hasActiveSubscription } =
    useSessionContext();

  const {
    isOnOnboardingPage,
    isOnMaintenancePage,
    isOnEmailVerificationPage,
    isOnSubscriptionPage,
  } = useRouterUtils();

  if (loading) {
    return (
      <div className="absolute inset-1/2 h-[15vh] w-[15vh] -translate-x-1/2 -translate-y-1/2">
        <Spinner className="h-12 w-12 sm:h-16 sm:w-16 lg:h-24 lg:w-24" />
      </div>
    );
  }

  if (!isEmailVerified && !isOnEmailVerificationPage) {
    return <Navigate to="/email-not-verified" replace />;
  }

  if (!!session && !isOnboarded && !isOnOnboardingPage) {
    return <Navigate to={ROUTES.ONBOARDING} replace />;
  }

  if (!session && isEmailVerified && !isOnMaintenancePage) {
    return <Navigate to={ROUTES.MAINTENANCE} replace />;
  }

  if (!!session && isOnboarded && isEmailVerified && isOnOnboardingPage) {
    return <Navigate to={ROUTES.CALENDAR} replace />;
  }

  if (
    !!session &&
    !hasActiveSubscription &&
    isOnboarded &&
    isEmailVerified &&
    !isOnSubscriptionPage
  ) {
    return <Navigate to={ROUTES.NO_SUBSCRIPTION} replace />;
  }

  if (!!session && hasActiveSubscription && isOnSubscriptionPage) {
    return <Navigate to={ROUTES.CALENDAR} replace />;
  }

  return <Outlet />;
};
