import { useLocation } from "react-router-dom";

import { EMAIL_VERIFICATION_ROUTES, ROUTES } from "../constants/routes";
export const useRouterUtils = () => {
  const location = useLocation();

  const isOnOnboardingPage = ROUTES.ONBOARDING === location.pathname;

  const isOnEmailVerificationPage = Object.values(EMAIL_VERIFICATION_ROUTES).some((link) =>
    location.pathname.includes(link),
  );

  const isInitial = location.pathname === "/";

  const isOnMaintenancePage = location.pathname.includes("maintenance");

  const isOnSubscriptionPage = location.pathname.includes("no-subscription");

  return {
    isInitial,
    isOnOnboardingPage,
    isOnEmailVerificationPage,
    isOnMaintenancePage,
    isOnSubscriptionPage,
  };
};
