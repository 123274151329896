import { ChangeEvent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TimeAutoComplete } from "@/components/ui/TimeAutoComplete";
import { TIME_AUTOCOMPLETE_STEP } from "@/constants";
import { generateTimeOptions, isValidTimeRange } from "@/utils/datetime";
import { cn } from "@/utils/utils";

import { Range } from "../../models";
import { EmployeeWorkScheduleItemMenu } from "./EmployeeWorkScheduleItemMenu";

const timeOptions = generateTimeOptions(TIME_AUTOCOMPLETE_STEP);

type EmployeeWorkScheduleItemRowProps = {
  range: Range;
  onUpdate: (newRange: Range) => void;
  onAddRange?: () => void;
  onRemoveRange?: () => void;
  totalRanges: number;
  index: number;
};

export const EmployeeWorkScheduleItemRow = ({
  range,
  onUpdate,
  onAddRange,
  onRemoveRange,
  totalRanges,
  index,
}: EmployeeWorkScheduleItemRowProps) => {
  const { t } = useTranslation();
  const { from, to } = range;
  const [validationError, setValidationError] = useState<string | null>(null);
  const { setError, clearErrors } = useFormContext();

  const handleChange = (key: "from" | "to") => (value: string | ChangeEvent<HTMLInputElement>) => {
    const newValue = typeof value === "string" ? value : value.target.value;
    const updatedRange = { ...range, [key]: newValue };

    if (!isValidTimeRange(updatedRange.from, updatedRange.to)) {
      setValidationError(t("errors.invalidTimeRange"));
      setError("workSchedule", {
        type: "manual",
        message: t("errors.invalidTimeRange"),
      });
    } else {
      setValidationError(null);
      clearErrors("workSchedule");
    }

    onUpdate(updatedRange);
  };

  return (
    <div
      className={cn("col-span-2 flex flex-col", {
        "mt-4 sm:col-start-2": index > 0,
      })}>
      <div className="relative flex h-12 gap-4">
        <TimeAutoComplete
          timeOptions={timeOptions}
          value={from}
          label={t("generic.from")}
          onChange={handleChange("from")}
        />
        <TimeAutoComplete
          timeOptions={timeOptions}
          value={to}
          label={t("generic.to")}
          onChange={handleChange("to")}
        />
        <EmployeeWorkScheduleItemMenu
          onAddRange={onAddRange}
          onRemoveRange={onRemoveRange}
          totalRanges={totalRanges}
        />
      </div>
      {validationError && (
        <span className="mt-1 rounded-md bg-red-50 px-2 py-1 text-xs text-red-600">
          {validationError}
        </span>
      )}
    </div>
  );
};
