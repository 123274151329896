import { SubscriptionStatus } from "@/types";

import { useCurrentSubscriptionQuery } from "../queries/CurrentSubscription.generated";

export const useSubscriptionStore = () => {
  const { data, loading } = useCurrentSubscriptionQuery();

  return {
    loading,
    subscription: data?.currentSubscription,
    isActive: data?.currentSubscription?.status === SubscriptionStatus.Active,
    isPastDue: data?.currentSubscription?.status === SubscriptionStatus.PastDue,
  };
};
