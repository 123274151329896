import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { CategoryFragmentDoc } from '../fragments/Category.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchCategoryQueryVariables = Types.Exact<{
  categoryId: Types.Scalars['UUID4'];
}>;


export type FetchCategoryQuery = { __typename?: 'RootQueryType', fetchCategory: { __typename?: 'Category', color: string, headCategoryId: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, id: any } };


export const FetchCategoryDocument = gql`
    query FetchCategory($categoryId: UUID4!) {
  fetchCategory(categoryId: $categoryId) {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useFetchCategoryQuery__
 *
 * To run a query within a React component, call `useFetchCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useFetchCategoryQuery(baseOptions: Apollo.QueryHookOptions<FetchCategoryQuery, FetchCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCategoryQuery, FetchCategoryQueryVariables>(FetchCategoryDocument, options);
      }
export function useFetchCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCategoryQuery, FetchCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCategoryQuery, FetchCategoryQueryVariables>(FetchCategoryDocument, options);
        }
export type FetchCategoryQueryHookResult = ReturnType<typeof useFetchCategoryQuery>;
export type FetchCategoryLazyQueryHookResult = ReturnType<typeof useFetchCategoryLazyQuery>;
export type FetchCategoryQueryResult = Apollo.QueryResult<FetchCategoryQuery, FetchCategoryQueryVariables>;