import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { EmployeeFragmentDoc } from '../fragments/Employee.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchEmployeesQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>;
  limit: Types.Scalars['Int'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchEmployeesQuery = { __typename?: 'RootQueryType', fetchEmployees: { __typename?: 'EmployeeConnection', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'EmployeeEdge', node: { __typename?: 'Employee', accountId: string, email: string, firstName?: string | null, lastName?: string | null, fullName: string, phone?: string | null, role: Types.Role, permissions?: any | null, isAvailableForAppointments: boolean, receiveEmails: boolean, isActive: boolean, imageUrl?: string | null, workSchedule?: any | null, treatments: Array<{ __typename?: 'Treatment', categoryId: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, id: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean }> } }> } };


export const FetchEmployeesDocument = gql`
    query FetchEmployees($query: String, $limit: Int!, $after: String) {
  fetchEmployees(query: $query, first: $limit, after: $after) {
    count
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...Employee
      }
    }
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useFetchEmployeesQuery__
 *
 * To run a query within a React component, call `useFetchEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEmployeesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchEmployeesQuery(baseOptions: Apollo.QueryHookOptions<FetchEmployeesQuery, FetchEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchEmployeesQuery, FetchEmployeesQueryVariables>(FetchEmployeesDocument, options);
      }
export function useFetchEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchEmployeesQuery, FetchEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchEmployeesQuery, FetchEmployeesQueryVariables>(FetchEmployeesDocument, options);
        }
export type FetchEmployeesQueryHookResult = ReturnType<typeof useFetchEmployeesQuery>;
export type FetchEmployeesLazyQueryHookResult = ReturnType<typeof useFetchEmployeesLazyQuery>;
export type FetchEmployeesQueryResult = Apollo.QueryResult<FetchEmployeesQuery, FetchEmployeesQueryVariables>;