import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ClientFragmentDoc } from '../fragments/Client.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateClientMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID4'];
  data: Types.UpdateClientInput;
}>;


export type UpdateClientMutation = { __typename?: 'RootMutationType', updateClient: { __typename?: 'Client', id: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Types.Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, phoneNew?: { __typename?: 'Phone', countryCode: string, number: string } | null, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, id: any }> } };


export const UpdateClientDocument = gql`
    mutation UpdateClient($id: UUID4!, $data: UpdateClientInput!) {
  updateClient(clientId: $id, data: $data) {
    ...Client
  }
}
    ${ClientFragmentDoc}`;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;