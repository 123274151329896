import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { EmployeeFragmentDoc } from '../fragments/Employee.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID4'];
  data: Types.UpdateEmployeeInput;
}>;


export type UpdateEmployeeMutation = { __typename?: 'RootMutationType', updateSalonEmployee: { __typename?: 'Employee', accountId: string, email: string, firstName?: string | null, lastName?: string | null, fullName: string, phone?: string | null, role: Types.Role, permissions?: any | null, isAvailableForAppointments: boolean, receiveEmails: boolean, isActive: boolean, imageUrl?: string | null, workSchedule?: any | null, treatments: Array<{ __typename?: 'Treatment', categoryId: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, id: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean }> } };


export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($id: UUID4!, $data: UpdateEmployeeInput!) {
  updateSalonEmployee(accountId: $id, data: $data) {
    ...Employee
  }
}
    ${EmployeeFragmentDoc}`;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;