import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Menu, Transition } from "@headlessui/react";

import {
  ArrowRightOnRectangleIcon,
  ChevronRightIcon,
  Cog8ToothIcon,
  UserCircleIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";

import { Float } from "@headlessui-float/react";

import { HeaderAvatar } from "@components/layout/header/HeaderAvatar";
import { Button } from "@components/ui/Button";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { useToasts } from "@providers/ToastsProvider";

import { usePaymentsStore } from "@features/payments/hooks";

import { useSessionContext } from "@/providers/SessionProvider";
import { cn } from "@/utils/utils";

export const UserDropdown = ({ onlyIcon = false }: { onlyIcon?: boolean }) => {
  const { t } = useTranslation();
  const { isMd } = useBreakpoint("md");
  const { showToast } = useToasts();

  const { session, logout } = useSessionContext();
  const { loading, createBillingPortalSession } = usePaymentsStore();

  const handleManagePayments = async () => {
    const { data, errors } = await createBillingPortalSession();

    if (errors) {
      showToast({
        type: "error",
        title: t("generic.oops"),
        description: t("generic.somethingWentWrong"),
      });
    } else if (data?.createBillingPortalSession?.url) {
      window.location.href = data.createBillingPortalSession.url;
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Float offset={2} placement={isMd ? "right-end" : "bottom-end"} portal>
        <Menu.Button
          className={cn(
            "inline-flex items-center justify-start rounded-md px-2 py-2 text-sm font-medium text-stone-500 focus:outline-none sm:mx-2",
            {
              "hover:bg-stone-50": isMd,
              "justify-center": onlyIcon,
            },
          )}>
          <div className="flex items-center">
            <HeaderAvatar icon={<UserCircleIcon />} />
            {!onlyIcon && <span className="ml-3 line-clamp-1 text-left">{session?.fullName}</span>}
          </div>
          {isMd && !onlyIcon && (
            <ChevronRightIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
          )}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items
            className="mx-2 w-56 origin-top-right divide-y divide-stone-100 rounded-md bg-white shadow-lg ring-1 ring-stone-300 focus:outline-none"
            as="ul">
            <Menu.Item as="li" className="py-1 text-stone-500 group-hover:text-stone-600">
              {({ active }) => (
                <div>
                  <Button
                    variant="neutral"
                    fullWidth
                    disabled={loading.loadingBillingPortalSession}
                    onClick={handleManagePayments}
                    className={cn(
                      "group flex w-full items-center px-4 py-2 text-xs",
                      active && "bg-stone-100",
                    )}>
                    <WalletIcon className="mr-3 h-4 w-4" aria-hidden="true" />
                    <span>{t("header.mainMenu.payments")}</span>
                  </Button>
                </div>
              )}
            </Menu.Item>
            <Menu.Item as="li" className="py-1 text-stone-500 group-hover:text-stone-600">
              {({ active }) => (
                <div>
                  <Button
                    variant="neutral"
                    fullWidth
                    to="/settings"
                    className={cn(
                      "group flex w-full items-center px-4 py-2 text-xs",
                      active && "bg-stone-100",
                    )}>
                    <Cog8ToothIcon className="mr-3 h-4 w-4" aria-hidden="true" />
                    <span>{t("settings.title")}</span>
                  </Button>
                </div>
              )}
            </Menu.Item>
            <Menu.Item as="li" className="py-1 text-red-500 group-hover:text-stone-600">
              {({ active }) => (
                <div>
                  <Button
                    variant="neutral"
                    fullWidth
                    onClick={logout}
                    className={cn(
                      "group flex w-full items-center px-4 py-2 text-xs",
                      active && "bg-stone-100",
                    )}>
                    <ArrowRightOnRectangleIcon className="mr-3 h-4 w-4" aria-hidden="true" />
                    <span>{t("header.mainMenu.logOut")}</span>
                  </Button>
                </div>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Float>
    </Menu>
  );
};
