import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type PriceFragment = { __typename?: 'Price', billingScheme?: string | null, productId?: string | null, id: string, type: string, amount: { __typename?: 'Money', currency: string, amount: number }, recurring?: { __typename?: 'PriceRecurring', interval?: Types.PriceRecurringInterval | null, intervalCount?: number | null, trialPeriodDays?: number | null } | null };

export const PriceFragmentDoc = gql`
    fragment Price on Price {
  amount {
    currency
    amount
  }
  recurring {
    interval
    intervalCount
    trialPeriodDays
  }
  billingScheme
  productId
  id
  type
}
    `;