import { useMemo } from "react";

import {
  SalonDeserialized,
  UpdateSalonInputDeserialized,
} from "@/features/salon/models/salonProfile";
import { AccountUpdateInput, CreateSalonInput, Maybe, namedOperations } from "@/types";

import { removeEmptyValues } from "../../../utils/utils";
import { useCreateSalonMutation } from "../mutations/CreateSalon.generated";
import { useOnboardMutation } from "../mutations/Onboard.generated";
import { useUpdateSalonMutation } from "../mutations/UpdateSalon.generated";
import { useFetchSalonQuery } from "../queries/FetchSalon.generated";

export const useSalonStore = ({ skipQuery } = { skipQuery: false }) => {
  const [createSalon, { loading: loadingCreateSalon }] = useCreateSalonMutation();
  const [updateSalon, { loading: loadingUpdateSalon }] = useUpdateSalonMutation();
  const [onboard] = useOnboardMutation();
  const { data, loading } = useFetchSalonQuery({
    skip: skipQuery,
  });

  const salon: Maybe<SalonDeserialized> = useMemo(
    () =>
      data?.fetchSalon && {
        ...data?.fetchSalon,
        ...(data?.fetchSalon?.socials && { socials: JSON.parse(data?.fetchSalon.socials) }),
        ...(data?.fetchSalon?.links && { links: JSON.parse(data?.fetchSalon.links) }),
      },
    [data?.fetchSalon],
  );

  return {
    loading,
    loadingCreateOrUpdate: loadingCreateSalon || loadingUpdateSalon,
    salon,
    onboard(salonData: CreateSalonInput, accountData: AccountUpdateInput) {
      return onboard({
        variables: { salonData, accountData },
        refetchQueries: [namedOperations.Query.CurrentSession],
      });
    },
    createSalon(data: CreateSalonInput) {
      return createSalon({
        variables: { data },
        refetchQueries: [namedOperations.Query.CurrentSession, namedOperations.Query.FetchSmsLimit],
      });
    },
    updateSalon(data: UpdateSalonInputDeserialized) {
      return updateSalon({
        variables: {
          data: {
            ...data,
            socials: data.socials ? JSON.stringify(removeEmptyValues(data.socials)) : undefined,
            links: data.links ? JSON.stringify(removeEmptyValues(data.links)) : undefined,
          },
        },
        refetchQueries: [namedOperations.Query.CurrentSession, namedOperations.Query.FetchSalon],
      });
    },
  };
};
