import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PriceFragmentDoc } from './Price.generated';
export type ProductFragment = { __typename?: 'Product', id?: string | null, name?: string | null, type?: Types.ProductType | null, price?: { __typename?: 'Price', billingScheme?: string | null, productId?: string | null, id: string, type: string, amount: { __typename?: 'Money', currency: string, amount: number }, recurring?: { __typename?: 'PriceRecurring', interval?: Types.PriceRecurringInterval | null, intervalCount?: number | null, trialPeriodDays?: number | null } | null } | null, prices: Array<{ __typename?: 'Price', billingScheme?: string | null, productId?: string | null, id: string, type: string, amount: { __typename?: 'Money', currency: string, amount: number }, recurring?: { __typename?: 'PriceRecurring', interval?: Types.PriceRecurringInterval | null, intervalCount?: number | null, trialPeriodDays?: number | null } | null }> };

export const ProductFragmentDoc = gql`
    fragment Product on Product {
  id
  name
  price {
    ...Price
  }
  prices {
    ...Price
  }
  type
}
    ${PriceFragmentDoc}`;