import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PencilIcon } from "@heroicons/react/24/outline";

import { Layout } from "@/components/layout/Layout";
import { Subheader } from "@/components/layout/subheader/Subheader";
import { Badge } from "@/components/ui/Badge";
import { Button } from "@/components/ui/Button";
import { Spinner } from "@/components/ui/Spinner";
import { CurrentSubscription } from "@/features/payments/components/CurrentSubscription";
import { Plans } from "@/features/payments/components/Plans";
import { usePaymentsStore } from "@/features/payments/hooks";
import { useSubscriptionStore } from "@/features/payments/hooks/use-subscription-store";
import { useToasts } from "@/providers/ToastsProvider";
import { SubscriptionStatus } from "@/types";

export const SubscriptionsView = () => {
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const { createBillingPortalSession } = usePaymentsStore();
  const { subscription, loading } = useSubscriptionStore();

  const handleManageClick = async () => {
    const { data, errors } = await createBillingPortalSession();

    if (errors) {
      showToast({
        type: "error",
        title: t("generic.oops"),
        description: t("generic.somethingWentWrong"),
      });
    } else if (data?.createBillingPortalSession?.url) {
      window.location.href = data.createBillingPortalSession.url;
    }
  };

  const renderSubscriptionStatus = () => {
    if (!subscription) {
      return null;
    }

    if (subscription.status === SubscriptionStatus.Active) {
      return (
        <Badge bordered size="sm" uppercase color="green">
          {t("subscriptions.status.active")}
        </Badge>
      );
    }

    if (subscription.status === SubscriptionStatus.PastDue) {
      return (
        <Badge bordered size="sm" color="red" uppercase>
          {t("subscriptions.status.pastDue")}
        </Badge>
      );
    }

    if (subscription.status === SubscriptionStatus.Trialing) {
      return (
        <Badge bordered size="sm" color="blue" uppercase>
          {t("subscriptions.status.trialing")}
        </Badge>
      );
    }
  };

  const isManageButtonVisible = useMemo(() => {
    if (!subscription) {
      return false;
    }

    return (
      subscription.status === SubscriptionStatus.Active ||
      subscription.status === SubscriptionStatus.PastDue
    );
  }, [subscription]);

  return (
    <Layout title={t("subscriptions.title")}>
      <div className="-ml-4 -mr-4 mt-4 flex max-w-6xl flex-col gap-8 rounded-xl px-4 sm:mt-8 sm:px-12 md:mx-auto md:px-12">
        {loading ? (
          <div className="flex justify-center p-10">
            <Spinner className="h-8 w-8" />
          </div>
        ) : (
          <>
            {subscription ? (
              <>
                <Subheader
                  title={t("subscriptions.title")}
                  rightSlot={
                    isManageButtonVisible ? (
                      <Button onClick={handleManageClick} size="small" startIcon={<PencilIcon />}>
                        {t("actions.manage")}
                      </Button>
                    ) : null
                  }
                  leftSlot={renderSubscriptionStatus()}
                />
                <CurrentSubscription subscription={subscription} />
              </>
            ) : (
              <Plans />
            )}
          </>
        )}
      </div>
    </Layout>
  );
};
