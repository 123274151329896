import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TreatmentFragmentDoc } from '../fragments/Treatment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddTreatmentMutationVariables = Types.Exact<{
  data: Types.AddTreatmentInput;
}>;


export type AddTreatmentMutation = { __typename?: 'RootMutationType', addTreatment: { __typename?: 'Treatment', categoryId: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, id: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean } };


export const AddTreatmentDocument = gql`
    mutation AddTreatment($data: AddTreatmentInput!) {
  addTreatment(data: $data) {
    ...Treatment
  }
}
    ${TreatmentFragmentDoc}`;
export type AddTreatmentMutationFn = Apollo.MutationFunction<AddTreatmentMutation, AddTreatmentMutationVariables>;

/**
 * __useAddTreatmentMutation__
 *
 * To run a mutation, you first call `useAddTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTreatmentMutation, { data, loading, error }] = useAddTreatmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddTreatmentMutation(baseOptions?: Apollo.MutationHookOptions<AddTreatmentMutation, AddTreatmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTreatmentMutation, AddTreatmentMutationVariables>(AddTreatmentDocument, options);
      }
export type AddTreatmentMutationHookResult = ReturnType<typeof useAddTreatmentMutation>;
export type AddTreatmentMutationResult = Apollo.MutationResult<AddTreatmentMutation>;
export type AddTreatmentMutationOptions = Apollo.BaseMutationOptions<AddTreatmentMutation, AddTreatmentMutationVariables>;