import { Trans, useTranslation } from "react-i18next";

import { addDays, format } from "date-fns";

import { Alert } from "@/components/ui/Alert";
import { Subscription2, SubscriptionStatus } from "@/types";

import { BusinessPlanCard } from "./BusinessPlanCard";
import { SalonPlanCard } from "./SalonPlanCard";

type CurrentSubscriptionProps = {
  subscription: Subscription2;
};

export const CurrentSubscription = ({ subscription }: CurrentSubscriptionProps) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-2xl bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {subscription.status === SubscriptionStatus.Active && (
          <div className="flex flex-col gap-4 text-sm text-stone-900">
            <Alert type="success">
              <p>
                {t("subscriptions.activeDescription")}{" "}
                <span className="font-semibold">
                  {format(new Date(subscription.currentPeriodEnd), "d MMMM yyyy")}.
                </span>{" "}
              </p>
            </Alert>
            <p>{t("subscriptions.manageSubscription")}</p>
            <p>{t("subscriptions.invoicesInfo")} </p>
            <p>{t("subscriptions.thanksForUsing")}</p>
          </div>
        )}
        {subscription.status === SubscriptionStatus.PastDue && (
          <div className="flex flex-col gap-4 text-sm text-stone-900">
            <Alert type="error">
              <p>
                <Trans
                  i18nKey="subscriptions.pastDueDescription"
                  values={{ date: format(addDays(new Date(), 5), "d MMMM yyyy") }}
                  components={{
                    datestyle: (
                      <span className="font-semibold">
                        {format(addDays(new Date(), 5), "d MMMM yyyy")}
                      </span>
                    ),
                  }}
                />
              </p>
            </Alert>

            <p>{t("subscriptions.manageSubscription")}</p>
            <p>{t("subscriptions.invoicesInfo")} </p>
            <p>{t("subscriptions.thanksForUsing")}</p>
          </div>
        )}
        {subscription.status === SubscriptionStatus.Trialing && (
          <div className="flex flex-col text-sm text-stone-900">
            <Alert>
              <p>
                {t("subscriptions.trialingDescription")}{" "}
                <span className="font-semibold">
                  {format(new Date(subscription.currentPeriodEnd), "d MMMM yyyy")}.
                </span>
              </p>
            </Alert>
            <div className="mt-8">
              <SalonPlanCard />
            </div>
            <BusinessPlanCard />
          </div>
        )}
      </div>
    </div>
  );
};
