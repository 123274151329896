import { ChangeEvent } from "react";
import Picker from "react-mobile-picker";

import { BottomSheet } from "./BottomSheet";
import { useMobileTimePicker } from "./hooks/use-mobile-time-picker";

type TimePickerOptions = {
  [key: string]: string[];
};

type MobileTimePickerProps = {
  value: string;
  timeOptions?: TimePickerOptions;
  label?: string;
  onChange?: (value: string | ChangeEvent<HTMLInputElement>) => void;
};

export const MobileTimePicker = ({ value, label, onChange }: MobileTimePickerProps) => {
  const { options, selectedOption, setSelectedOption } = useMobileTimePicker(value);

  const handlePickerChange = () => {
    const formattedValue = `${selectedOption.hour
      .toString()
      .padStart(2, "0")}:${selectedOption.minute.toString().padStart(2, "0")}`;

    onChange && onChange(formattedValue);
  };

  return (
    <BottomSheet
      isDismissable={false}
      onBlur={handlePickerChange}
      trigger={
        <div className="relative flex h-12 w-full items-center justify-between rounded-md border border-stone-300 bg-white px-3 text-xs text-stone-400">
          {label && <span className="left-3 z-0 text-xs text-stone-400">{label}</span>}
          <span className="flex appearance-none border-none bg-transparent pl-8 text-right text-sm text-stone-500 ring-0 focus:border-none focus:ring-0 focus:ring-offset-0">
            {value}
          </span>
        </div>
      }>
      <Picker value={selectedOption} onChange={setSelectedOption} className="picker max-w-sm px-10">
        {Object.entries(options).map(([k, v]) => (
          <Picker.Column key={v.length} name={k}>
            {v.map((item) => (
              <Picker.Item key={item} value={item}>
                <span className="font-semibold text-stone-500">
                  {item.toString().padStart(2, "0")}
                </span>
              </Picker.Item>
            ))}
          </Picker.Column>
        ))}
      </Picker>
    </BottomSheet>
  );
};
