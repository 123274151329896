import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppointmentFragmentDoc } from '../fragments/Appointment.generated';
import { BlockedSlotFragmentDoc } from '../fragments/BlockedSlot.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchCalendarByEmployeeQueryVariables = Types.Exact<{
  filters: Types.AppointmentFilters;
}>;


export type FetchCalendarByEmployeeQuery = { __typename?: 'RootQueryType', fetchCalendarByEmployee: Array<{ __typename?: 'EmployeeCalendarResult', employee: { __typename?: 'CalendarEmployee', accountId: string, firstName?: string | null, lastName?: string | null, name?: string | null, role: Types.Role, isActive: boolean, imageUrl?: string | null }, entries: Array<{ __typename?: 'Appointment', appointmentId: any, clientId?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: Types.AppointmentStatus, totalPrice: number, paymentMethodId?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentId: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentId: any, employeeId?: any | null, treatmentId: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } | { __typename?: 'BlockedSlot', description?: string | null, employeeName: string, employeeId: any, title?: string | null, id: any, insertedAt: any, timeRange: { __typename?: 'Timerange', from: any, to: any } }> }> };


export const FetchCalendarByEmployeeDocument = gql`
    query FetchCalendarByEmployee($filters: AppointmentFilters!) {
  fetchCalendarByEmployee(filters: $filters) {
    employee {
      accountId
      firstName
      lastName
      name
      role
      isActive
      imageUrl
    }
    entries {
      ...Appointment
      ...BlockedSlot
    }
  }
}
    ${AppointmentFragmentDoc}
${BlockedSlotFragmentDoc}`;

/**
 * __useFetchCalendarByEmployeeQuery__
 *
 * To run a query within a React component, call `useFetchCalendarByEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCalendarByEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCalendarByEmployeeQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFetchCalendarByEmployeeQuery(baseOptions: Apollo.QueryHookOptions<FetchCalendarByEmployeeQuery, FetchCalendarByEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCalendarByEmployeeQuery, FetchCalendarByEmployeeQueryVariables>(FetchCalendarByEmployeeDocument, options);
      }
export function useFetchCalendarByEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCalendarByEmployeeQuery, FetchCalendarByEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCalendarByEmployeeQuery, FetchCalendarByEmployeeQueryVariables>(FetchCalendarByEmployeeDocument, options);
        }
export type FetchCalendarByEmployeeQueryHookResult = ReturnType<typeof useFetchCalendarByEmployeeQuery>;
export type FetchCalendarByEmployeeLazyQueryHookResult = ReturnType<typeof useFetchCalendarByEmployeeLazyQuery>;
export type FetchCalendarByEmployeeQueryResult = Apollo.QueryResult<FetchCalendarByEmployeeQuery, FetchCalendarByEmployeeQueryVariables>;