import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type EquipmentItemFragment = { __typename?: 'EquipmentItem', id: any, name: string, description?: string | null, treatments: Array<{ __typename?: 'EquipmentItemTreatment', treatmentName: string, treatmentId: any }> };

export const EquipmentItemFragmentDoc = gql`
    fragment EquipmentItem on EquipmentItem {
  id
  name
  description
  treatments {
    treatmentName
    treatmentId
  }
}
    `;