import Picker from "react-mobile-picker";

import { format, set } from "date-fns";

import { BottomSheet } from "./BottomSheet";
import { useMobileDatePicker } from "./hooks/use-mobile-date-picker";

type MobileDatePickerProps = {
  value: Date;
  onChange?: (value: Date) => void;
};

export const MobileDatePicker = ({ value, onChange }: MobileDatePickerProps) => {
  const { options, selectedOption, setSelectedOption } = useMobileDatePicker(value);

  const handlePickerChange = (pickerValue: { year: number; month: number; day: number }) => {
    setSelectedOption(pickerValue);
  };

  const handlePickerClose = () => {
    onChange &&
      onChange(
        set(new Date(), {
          year: selectedOption.year,
          month: selectedOption.month,
          date: selectedOption.day,
        }),
      );
  };

  return (
    <BottomSheet
      isDismissable={false}
      onBlur={handlePickerClose}
      trigger={
        <div className="relative flex h-12 w-full items-center rounded-md border border-stone-300 bg-white px-3 text-xs text-stone-400">
          <span className="flex text-sm text-stone-500">
            {format(
              set(new Date(), {
                year: selectedOption.year,
                month: selectedOption.month,
                date: selectedOption.day,
              }),
              "dd.MM.yyyy",
            )}
          </span>
        </div>
      }>
      <Picker
        value={selectedOption}
        onChange={handlePickerChange}
        className="picker max-w-sm px-10">
        {Object.entries(options).map(([k, v]) => {
          return (
            <Picker.Column key={v.length} name={k}>
              {v.map((item) => (
                <Picker.Item key={item} value={item}>
                  {k === "month" ? (
                    <span className="font-semibold capitalize text-stone-500">
                      {format(new Date(0, item), "LLLL")}
                    </span>
                  ) : (
                    <span className="font-semibold text-stone-500">{item}</span>
                  )}
                </Picker.Item>
              ))}
            </Picker.Column>
          );
        })}
      </Picker>
    </BottomSheet>
  );
};
