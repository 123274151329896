import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { RadioGroup } from "@headlessui/react";

import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";
import { Spinner } from "@components/ui/Spinner";

import { useToasts } from "@providers/ToastsProvider";

import { usePaymentsStore } from "@features/payments/hooks";

import { Button } from "@/components/ui/Button";
import { PaymentMode, Product, ProductType } from "@/types";
import { cn } from "@/utils/utils";

import { formatPrice } from "../utils";

type GetSmsPackageFormProps = {
  onClose: () => void;
};

type GetSmsPackageDialogProps = GetSmsPackageFormProps & {
  open: boolean;
};

export const GetSmsPackageDialog = ({ open, onClose }: GetSmsPackageDialogProps) => {
  const { t } = useTranslation();

  return (
    <DefaultDialog open={open} onClose={onClose} title={t("sms.topUpTitle")}>
      <GetSmsPackageForm onClose={onClose} />
    </DefaultDialog>
  );
};

const GetSmsPackageForm = ({ onClose }: GetSmsPackageFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const { loading, listProducts, orderProduct } = usePaymentsStore();
  const [products, setProducts] = useState<Product[]>([]);
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    listProducts(ProductType.Sms).then((result) => {
      setProducts(result.data?.listProducts || []);
    });
  }, []);

  const handleSubmit = async () => {
    const orderProductInput = {
      paymentMode: PaymentMode.Prepaid,
      product: {
        priceId: product?.price?.id,
        productId: product?.price?.productId,
        quantity: 1,
        type: ProductType.Sms,
      },
    };

    try {
      const { data } = await orderProduct(orderProductInput);

      if (data && data.orderProduct?.url) {
        window.location.href = data.orderProduct?.url;
      }
    } catch (error) {
      console.error(error);

      showToast({
        type: "error",
        title: t("generic.oops"),
        description: t("generic.somethingWentWrong"),
      });

      onClose();
      return;
    }

    onClose();
  };

  if (products.length === 0 || loading.loadingListProducts) {
    return (
      <div className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <p className="text-center text-xs text-stone-500">{t("sms.topUpDescription")}</p>
      <RadioGroup value={product} onChange={setProduct} className="flex flex-col gap-2">
        {products.map((product) => (
          <RadioGroup.Option
            value={product}
            key={product?.price?.id}
            className={({ checked }) =>
              cn(
                "relative flex cursor-pointer rounded-sm border px-3 py-1 shadow-md transition-colors focus:outline-none",
                checked ? "border-gold-500 text-gold-500" : "border-stone-300 text-stone-400",
              )
            }>
            {({ checked }) => (
              <RadioGroup.Label as="div" className="w-full divide-y">
                <div className="flex items-center gap-2 py-2 font-medium">
                  {checked ? <CheckIcon className="h-6 w-6" /> : <PlusIcon className="h-6 w-6" />}
                  {product?.name}
                </div>
                {product?.price?.amount?.amount && product.price.amount.currency && (
                  <div className="py-2 text-xs">
                    {t("sms.buyForPrice", {
                      price: formatPrice({
                        price: product.price.amount.amount,
                        currency: product.price.amount.currency,
                      }),
                    })}
                  </div>
                )}
              </RadioGroup.Label>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      <div className="align-center mt-4 flex flex-col">
        <Button
          className="mt-4"
          fullWidth
          disabled={(product && !product?.price?.id) || loading.loadingOrderProduct}
          onClick={handleSubmit}>
          {t("sms.buySms")}
        </Button>
      </div>
    </div>
  );
};
