import { ForwardedRef, forwardRef, LabelHTMLAttributes } from "react";

import { cn } from "@/utils/utils";

type LabelProps = {
  screenReaderOnly?: boolean;
  required?: boolean;
};

export const Label = forwardRef(
  (
    {
      children,
      required,
      screenReaderOnly = false,
      ...delegated
    }: LabelProps & LabelHTMLAttributes<HTMLLabelElement>,
    ref: ForwardedRef<HTMLLabelElement>,
  ) => {
    return (
      <label
        ref={ref}
        className={cn("mb-1 block text-xs text-stone-500", { "sr-only": screenReaderOnly })}
        {...delegated}>
        {children}
        {required && <span className="text-red-500">*</span>}
      </label>
    );
  },
);
