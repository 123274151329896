import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";

import LogoSvg from "@assets/images/logo_dashboard.svg";

import { Button } from "@/components/ui/Button";
import { TextField } from "@/components/ui/TextField";
import { GENERIC_NAME, NAME } from "@/constants/validations";
import { ROUTES } from "@/features/router/constants/routes";
import { useSalonStore } from "@/features/salon/hooks";
import { useSessionContext } from "@/providers/SessionProvider";

type FormData = {
  firstName: string;
  lastName: string;
  salonName: string;
};

export const OnboardingView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { session, logout } = useSessionContext();
  const { loadingCreateOrUpdate, onboard } = useSalonStore({ skipQuery: true });

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = useForm({
    mode: "all",
    defaultValues: {
      firstName: session?.firstName || "",
      lastName: session?.lastName || "",
      salonName: "",
    },
  });

  const handleOnboarding: SubmitHandler<FormData> = async (data) => {
    const salonData = {
      name: data.salonName,
    };

    const accountData = {
      firstName: data.firstName,
      lastName: data.lastName,
    };

    try {
      await onboard(salonData, accountData);
    } catch (error) {
      console.error("🚀 ~ OnboardingView ~ error", error);
    }

    navigate(ROUTES.SALON);
  };

  const firstName = watch("firstName");

  return (
    <div className="mx-auto flex h-full min-h-screen flex-col px-4 py-8 sm:max-w-6xl">
      <nav className="flex items-center justify-between">
        <div className="flex items-center">
          <img src={LogoSvg} alt="logo" className="h-8 sm:h-10" />
        </div>
        <div>
          <Button
            size="small"
            variant="primary-inline"
            startIcon={<ArrowRightOnRectangleIcon />}
            onClick={logout}>
            {t("header.mainMenu.logOut")}
          </Button>
        </div>
      </nav>
      <main className="mt-8 flex flex-1 flex-col items-center justify-center gap-10 sm:mt-16">
        <div className="mx-auto flex w-full flex-1 flex-col rounded-xl bg-white px-4 py-8 sm:justify-center ">
          <div className="mx-auto max-w-sm">
            <header className="mb-6 space-y-2">
              <h1 className="mb-2 font-serif text-2xl font-medium text-stone-900 sm:text-3xl">
                {t("onboarding.firstStep.title")}
                {firstName && ` ${firstName}`}!
              </h1>
              <p className="mb-4 text-sm text-stone-500">{t("onboarding.firstStep.description")}</p>
            </header>
            <form className="space-y-6" onSubmit={handleSubmit(handleOnboarding)} noValidate>
              <div className="space-y-6">
                <TextField
                  {...register("firstName", { required: true, ...NAME })}
                  type="text"
                  label={t("generic.firstName")}
                  placeholder={t("generic.firstName")}
                  errorMessage={
                    errors.firstName &&
                    t(`validation.${errors.firstName.type}`, {
                      name: t("generic.firstName"),
                      ...NAME,
                    })
                  }
                />
                <TextField
                  {...register("lastName", { required: true, ...NAME })}
                  type="text"
                  label={t("generic.lastName")}
                  placeholder={t("generic.lastName")}
                  errorMessage={
                    errors.lastName &&
                    t(`validation.${errors.lastName.type}`, {
                      name: t("generic.lastName"),
                      ...NAME,
                    })
                  }
                />
                <TextField
                  {...register("salonName", GENERIC_NAME)}
                  label={t("salonProfile.salonName.label")}
                  placeholder={t("salonProfile.salonName.placeholder")}
                  errorMessage={
                    errors.salonName &&
                    t(`validation.${errors.salonName.type}`, {
                      name: t("salonProfile.salonName.label"),
                    })
                  }
                />
              </div>
              <div className="space-y-2">
                <p className="text-xs text-stone-400">{t("onboarding.firstStep.hint")}</p>
                <Button type="submit" fullWidth disabled={!isValid || loadingCreateOrUpdate}>
                  {t("generic.continue")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </main>
      <footer className="mt-6 space-y-2 sm:mt-10">
        <p className="text-xs text-stone-400">
          {t("generic.estetifyCopy", {
            year: new Date().getFullYear(),
          })}
        </p>
      </footer>
    </div>
  );
};
