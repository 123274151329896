import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckEquipmentsForTreatmentsAvailabilityQueryVariables = Types.Exact<{
  data: Array<Types.CheckEquipmentsForTreatmentsAvailabilityInput> | Types.CheckEquipmentsForTreatmentsAvailabilityInput;
}>;


export type CheckEquipmentsForTreatmentsAvailabilityQuery = { __typename?: 'RootQueryType', checkEquipmentsForTreatmentsAvailability: Array<{ __typename?: 'AvailableEquipmentsForTreatment', treatmentId: any, availableEquipments: Array<{ __typename?: 'AvailableEquipment', equipmentItemId: any, equipmentItemName: string, equipmentItemDescription?: string | null }> }> };


export const CheckEquipmentsForTreatmentsAvailabilityDocument = gql`
    query CheckEquipmentsForTreatmentsAvailability($data: [CheckEquipmentsForTreatmentsAvailabilityInput!]!) {
  checkEquipmentsForTreatmentsAvailability(data: $data) {
    treatmentId
    availableEquipments {
      equipmentItemId
      equipmentItemName
      equipmentItemDescription
    }
  }
}
    `;

/**
 * __useCheckEquipmentsForTreatmentsAvailabilityQuery__
 *
 * To run a query within a React component, call `useCheckEquipmentsForTreatmentsAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEquipmentsForTreatmentsAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEquipmentsForTreatmentsAvailabilityQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckEquipmentsForTreatmentsAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<CheckEquipmentsForTreatmentsAvailabilityQuery, CheckEquipmentsForTreatmentsAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckEquipmentsForTreatmentsAvailabilityQuery, CheckEquipmentsForTreatmentsAvailabilityQueryVariables>(CheckEquipmentsForTreatmentsAvailabilityDocument, options);
      }
export function useCheckEquipmentsForTreatmentsAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckEquipmentsForTreatmentsAvailabilityQuery, CheckEquipmentsForTreatmentsAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckEquipmentsForTreatmentsAvailabilityQuery, CheckEquipmentsForTreatmentsAvailabilityQueryVariables>(CheckEquipmentsForTreatmentsAvailabilityDocument, options);
        }
export type CheckEquipmentsForTreatmentsAvailabilityQueryHookResult = ReturnType<typeof useCheckEquipmentsForTreatmentsAvailabilityQuery>;
export type CheckEquipmentsForTreatmentsAvailabilityLazyQueryHookResult = ReturnType<typeof useCheckEquipmentsForTreatmentsAvailabilityLazyQuery>;
export type CheckEquipmentsForTreatmentsAvailabilityQueryResult = Apollo.QueryResult<CheckEquipmentsForTreatmentsAvailabilityQuery, CheckEquipmentsForTreatmentsAvailabilityQueryVariables>;