import { OrderProductInput, ProductType } from "@/types";

import { useCreateBillingPortalSessionMutation } from "../mutations/CreateBillingPortalSession.generated";
import { useOrderProductMutation } from "../mutations/OrderProduct.generated";
import { useListProductsLazyQuery } from "../queries/ListProducts.generated";

export const usePaymentsStore = () => {
  const [listProducts, { loading: loadingListProducts }] = useListProductsLazyQuery();
  const [orderProduct, { loading: loadingOrderProduct }] = useOrderProductMutation();
  const [createBillingPortalSession, { loading: loadingBillingPortalSession }] =
    useCreateBillingPortalSessionMutation();

  return {
    loading: {
      loadingListProducts,
      loadingOrderProduct,
      loadingBillingPortalSession,
    },
    orderProduct(data: OrderProductInput) {
      return orderProduct({
        variables: { data },
      });
    },
    createBillingPortalSession,
    listProducts(type: ProductType) {
      return listProducts({
        variables: { type },
      });
    },
  };
};
