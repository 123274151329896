import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ProductFragmentDoc } from '../fragments/Product.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListProductsQueryVariables = Types.Exact<{
  type?: Types.InputMaybe<Types.ProductType>;
}>;


export type ListProductsQuery = { __typename?: 'RootQueryType', listProducts: Array<{ __typename?: 'Product', id?: string | null, name?: string | null, type?: Types.ProductType | null, price?: { __typename?: 'Price', billingScheme?: string | null, productId?: string | null, id: string, type: string, amount: { __typename?: 'Money', currency: string, amount: number }, recurring?: { __typename?: 'PriceRecurring', interval?: Types.PriceRecurringInterval | null, intervalCount?: number | null, trialPeriodDays?: number | null } | null } | null, prices: Array<{ __typename?: 'Price', billingScheme?: string | null, productId?: string | null, id: string, type: string, amount: { __typename?: 'Money', currency: string, amount: number }, recurring?: { __typename?: 'PriceRecurring', interval?: Types.PriceRecurringInterval | null, intervalCount?: number | null, trialPeriodDays?: number | null } | null }> }> };


export const ListProductsDocument = gql`
    query ListProducts($type: ProductType) {
  listProducts(type: $type) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useListProductsQuery__
 *
 * To run a query within a React component, call `useListProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProductsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListProductsQuery(baseOptions?: Apollo.QueryHookOptions<ListProductsQuery, ListProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListProductsQuery, ListProductsQueryVariables>(ListProductsDocument, options);
      }
export function useListProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListProductsQuery, ListProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListProductsQuery, ListProductsQueryVariables>(ListProductsDocument, options);
        }
export type ListProductsQueryHookResult = ReturnType<typeof useListProductsQuery>;
export type ListProductsLazyQueryHookResult = ReturnType<typeof useListProductsLazyQuery>;
export type ListProductsQueryResult = Apollo.QueryResult<ListProductsQuery, ListProductsQueryVariables>;