import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SalonPaymentMethodFragmentDoc } from '../fragments/SalonPaymentMethod.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSalonPaymentMethodsMutationVariables = Types.Exact<{
  data: Array<Types.SalonPaymentMethodInput> | Types.SalonPaymentMethodInput;
}>;


export type UpdateSalonPaymentMethodsMutation = { __typename?: 'RootMutationType', updateSalonPaymentMethods: Array<{ __typename?: 'SalonPaymentMethod', default: boolean, enabled: boolean, name: Types.SalonPaymentMethods, salonId: any, id: any }> };


export const UpdateSalonPaymentMethodsDocument = gql`
    mutation UpdateSalonPaymentMethods($data: [SalonPaymentMethodInput!]!) {
  updateSalonPaymentMethods(data: $data) {
    ...SalonPaymentMethod
  }
}
    ${SalonPaymentMethodFragmentDoc}`;
export type UpdateSalonPaymentMethodsMutationFn = Apollo.MutationFunction<UpdateSalonPaymentMethodsMutation, UpdateSalonPaymentMethodsMutationVariables>;

/**
 * __useUpdateSalonPaymentMethodsMutation__
 *
 * To run a mutation, you first call `useUpdateSalonPaymentMethodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalonPaymentMethodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalonPaymentMethodsMutation, { data, loading, error }] = useUpdateSalonPaymentMethodsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSalonPaymentMethodsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalonPaymentMethodsMutation, UpdateSalonPaymentMethodsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSalonPaymentMethodsMutation, UpdateSalonPaymentMethodsMutationVariables>(UpdateSalonPaymentMethodsDocument, options);
      }
export type UpdateSalonPaymentMethodsMutationHookResult = ReturnType<typeof useUpdateSalonPaymentMethodsMutation>;
export type UpdateSalonPaymentMethodsMutationResult = Apollo.MutationResult<UpdateSalonPaymentMethodsMutation>;
export type UpdateSalonPaymentMethodsMutationOptions = Apollo.BaseMutationOptions<UpdateSalonPaymentMethodsMutation, UpdateSalonPaymentMethodsMutationVariables>;