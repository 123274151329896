import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ClientTagFragmentDoc } from '../../fragments/ClientTag.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateClientTagMutationVariables = Types.Exact<{
  data: Types.ClientTagInput;
}>;


export type CreateClientTagMutation = { __typename?: 'RootMutationType', createClientTag: { __typename?: 'ClientTag', color: string, name: string, id: any } };


export const CreateClientTagDocument = gql`
    mutation CreateClientTag($data: ClientTagInput!) {
  createClientTag(data: $data) {
    ...ClientTag
  }
}
    ${ClientTagFragmentDoc}`;
export type CreateClientTagMutationFn = Apollo.MutationFunction<CreateClientTagMutation, CreateClientTagMutationVariables>;

/**
 * __useCreateClientTagMutation__
 *
 * To run a mutation, you first call `useCreateClientTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientTagMutation, { data, loading, error }] = useCreateClientTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateClientTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientTagMutation, CreateClientTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientTagMutation, CreateClientTagMutationVariables>(CreateClientTagDocument, options);
      }
export type CreateClientTagMutationHookResult = ReturnType<typeof useCreateClientTagMutation>;
export type CreateClientTagMutationResult = Apollo.MutationResult<CreateClientTagMutation>;
export type CreateClientTagMutationOptions = Apollo.BaseMutationOptions<CreateClientTagMutation, CreateClientTagMutationVariables>;