import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type CategoryFragment = { __typename?: 'Category', color: string, headCategoryId: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, id: any };

export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  color
  headCategoryId
  insertedAt
  isDeleted
  name
  updatedAt
  id
}
    `;